import React from 'react';
import Videoscard from './Videoscard';

const Latest = () => {
    return (
        <div className=''>
            <div className='row'>
                <div className='col-xxl-3 col-lg-4 col-sm-6 col-sm-6'> 
                    <Videoscard/>
                </div>

                <div className='col-xxl-3 col-lg-4 col-sm-6 col-sm-6'> 
                   <Videoscard/>
                </div>

                <div className='col-xxl-3 col-lg-4 col-sm-6'> 
                   <Videoscard/>
                </div>

                <div className='col-xxl-3 col-lg-4 col-sm-6'> 
                   <Videoscard/>
                </div>

                <div className='col-xxl-3 col-lg-4 col-sm-6'> 
                   <Videoscard/>
                </div>

                <div className='col-xxl-3 col-lg-4 col-sm-6'> 
                   <Videoscard/>
                </div>
            </div>

        </div>
    )
}

export default Latest
