import React from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import {
    Link,
} from "react-router-dom";
import { Apple, Google } from "@mui/icons-material";

const Registration = () => {
    return (
        <div>
            <div className='Login_Page'>
                <div className='card'>
                <div>
                    <h4>Welcome to the Video pro!</h4>
                    <p className='sm-text'>Please Sign-in to your account and start the adventure</p>
                </div>
                    <Form>
                        <Form.Group className="" controlId="">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Enter Username" />
                        </Form.Group>

                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="example@gmail.com" />
                        </Form.Group>

                        <Form.Group className="" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>
                    </Form>

                    <Button className='Submit'>Sign In</Button>
                    <div className="lines">
                    <div className="hr">
                        <hr />
                    </div>
                    <div className="text">
                        <h6>OR</h6>
                    </div>
                    <div className="hr">
                        <hr />
                    </div>
                </div>


                <div className='Googles-btn'>
                    <Button className=''><Google /></Button>
                    <Button className='ms-2'><Apple /></Button>
                </div>

                    <div className='New-account'>
                        <span>Already have an account?</span>{' '}
                        <Link to='/Login' className='ms-1'>
                            Log in instead
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration
