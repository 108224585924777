import React from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import {
    Link,
  } from "react-router-dom";

const Forget = () => {
  return (
    <div>
      <div className='Login_Page'>
            <div className='card'>
                <div>
                    <h4>Welcome to the Video pro!</h4>
                    <p className='sm-text'>Please enter your email to recover your account</p>
                </div>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" placeholder="example@gmail.com" />
                    </Form.Group>
                </Form>

                <Button className='Submit'>Send code</Button>
                <div className='New_account'>
                        <Link to='/signup' className='ms-1'>
                            Create an account
                        </Link>
                    </div> 
            </div>
        </div>
    </div>
  )
}

export default Forget
