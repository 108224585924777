import React from 'react';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
    Link,
} from "react-router-dom";

// Icons
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import GroupsIcon from '@mui/icons-material/Groups';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import Responsivesider from '../Sidebar-lft/Responsivesider';

const Responsivetopbar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='Topbar d-lg-none'>
            <Navbar expand="lg" className="bg-body-tertiary">

                {/* Offcanvas */}
                <Button variant="primary" onClick={handleShow} className='Offcanvas-btn'>
                    <img src="images/toggle.png" alt="" />
                </Button>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav
                            className="mx-auto my-2 my-lg-0"
                            //   style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Link to="/" className='Nav-link'><HomeIcon /> Home</Link>
                            <a href='' className='Nav-link'><GroupsIcon /> About</a>
                            <a href='' className='Nav-link'><VideoLibraryIcon /> Videos</a>
                            <Link to="/channel" className='Nav-link'><LiveTvIcon /> Channels</Link>

                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>


                {/* logo */}
                <Navbar.Brand href="" className='Navbar-logo mx-auto'><img src="/images/Logo.png" alt="Logo" /></Navbar.Brand>

                {/* Login Button  */}
                <div className='Login-portion ms-0'>

                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <AccountCircleIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Link to="/login"> <Button>Login</Button> </Link>
                        </Dropdown.Menu>

                    </Dropdown>
                </div>


                <div className='d-flex w-100 mt-4'>
                <div className=''>
                <Responsivesider/>
                </div>

                <Form className="flex-box">
                    <Form.Control
                        type="search"
                        placeholder="Enter Keyword"
                        className=""
                    />
                    <SearchIcon className='icon' />
                </Form>
                </div>
            </Navbar>
        </div>

    )
}

export default Responsivetopbar
