import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className="row">
        <div className="col-lg-4">
          <img src="/images/Logo.png" alt="" className='img-logo' />
          <div className='Footer_inner'>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro exercitationem laborum id quos, quaerat commodi. Saepe, rerum sit. Magni, dolorem.
            </p>
            <div className='flex-box-three'>
              <a href='#'> <FacebookIcon /></a>
              <a href='#'>  <YouTubeIcon /></a>
              <a href='#'>   <TwitterIcon /></a>
              <a href='#'>  <LinkedInIcon /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 text-center">
          <h4>Quick Links </h4>
          <div className='Footer_inner'>
            <p><a href="">Home</a></p>
            <p><a href="">About</a></p>
            <p><a href="">Videos</a></p>
            <p><a href="">Channels</a></p>
          </div>
        </div>
        <div className="col-lg-4 text-center">
          <h4>Contacts </h4>
          <div className='Footer_inner'>
            <p><a href="#">Flat 20, Reynolds Neck, North Helenaville, FV77 8WS</a></p>
            <p><a href="#">1-800- 936-2793</a></p>
            <p><a href='#'>Videopro.gmail.com</a></p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
