import './Global.scss';
import './Responsive.scss'
import { Route, Routes } from 'react-router-dom';
import Allpages from './Pages/Allpages';
import Login from './Components/Login-Pages/Login';
import Registration from './Components/Login-Pages/Registration';
import Forget from './Components/Login-Pages/Forget';
import Footer from './Components/Footer';
import Topbar from './Components/Topbar/Topbar';
import Responsivetopbar from './Components/Topbar/Responsivetopbar';

function App() {
  return (
    <div className="App">
      <Topbar/>
       <Responsivetopbar/>
      <div>
      <Routes>
        <Route path="*" element={<Allpages />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Registration />} />
        <Route path="/forget" element={<Forget />} />
      </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
