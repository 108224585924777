import React from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import {
  Link,
} from "react-router-dom";

const Categories = () => {
  return (
    <div className='Category'>
       <h5>  Categories</h5>
      <Link to="/channel" className='flex-box-four'>
        <div>
            <VideogameAssetIcon/>
        </div>
        <div className='channel-type'>
            <p className='Type'>GAME (13)</p>
        </div>
      </Link> 

      <Link to="/channel" className='flex-box-four'>
        <div>
            <PlayCircleIcon/>
        </div>
        <div className='channel-type'>
            <p className='Type'>MOVIE (15)</p>
        </div>
      </Link> 

      <Link to="/channel" className='flex-box-four'>
        <div>
            <MusicNoteIcon/>
        </div>
        <div className='channel-type'>
            <p className='Type'>MUSIC (14)</p>
        </div>
      </Link> 

      
    </div>
  )
}

export default Categories
