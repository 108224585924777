import React from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { Apple, Google } from "@mui/icons-material";
import {
    Link,
} from "react-router-dom";

const Login = () => {
    return (
        <div className='Login_Page'>
            <div className='card'>
                <div>
                    <h4>Welcome to the Video pro!</h4>
                    <p className='sm-text'>Please Log-in to your account and start the adventure</p>
                </div>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" placeholder="example@gmail.com" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password:</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Link to='/forget' >
                        Forget password?
                    </Link>
                </Form>

                <Button className='Submit'>Log In</Button>


                <div className="lines">
                    <div className="hr">
                        <hr />
                    </div>
                    <div className="text">
                        <h6>OR</h6>
                    </div>
                    <div className="hr">
                        <hr />
                    </div>
                </div>


                <div className='Googles-btn'>
                    <Button className=''><Google /></Button>
                    <Button className='ms-2'><Apple /></Button>
                </div>



                <div className='New-account'>
                    <span>Need an account?</span>
                    <Link to='/signup' className='ms-1'>
                        Sign up
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Login
