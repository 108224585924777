import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SearchIcon from '@mui/icons-material/Search';
import Dropdown from 'react-bootstrap/Dropdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Link,
} from "react-router-dom";

const Topbar = () => {
  return (
    <div className='Topbar d-lg-block d-none'>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="" className='Navbar-logo'><img src="/images/Logo.png" alt="Logo" /></Navbar.Brand>
          <Form className="flex-box">
            <Form.Control
              type="search"
              placeholder="Enter Keyword"
              className=""
            />
            <SearchIcon className='icon' />
          </Form>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0"
              navbarScroll
            >
              <Link to="/" className='Nav-link'>Home</Link>
              <a href='' className='Nav-link'>About</a>
              <a href='' className='Nav-link'>Videos</a>
              <Link to="/channel" className='Nav-link'>Channels</Link>

            </Nav>
            <div className='Login-portion'>
              <Button className='Submit'>Submit Video</Button>

              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <AccountCircleIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to="/login"> <Button>Login</Button> </Link>
                </Dropdown.Menu>

              </Dropdown>
            </div>


          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Topbar
