import React from 'react';
import Card from 'react-bootstrap/Card';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CommentIcon from '@mui/icons-material/Comment';
import {
    Link,
} from "react-router-dom";

const Videoscard = () => {
    return (
        <div className='Cards-box'>
            <div className='Latest-tab'>
                <Card>
                    <div className="Card_img">
                        <img src="images/Latest-img.jpg" alt="" />
                        <Link to="/videos" className="play_button">Play All</Link>
                    </div>

                    <Card.Body>
                        <Card.Title>JWPlayer Embed Code</Card.Title>
                        <Card.Text>
                            This post uses JWPlayer Embed Code. Scientific research shows this is a great way to immediately increase happiness. You can do it anywhere and it does not cost anything. Sed ut perspiciatis unde omnis iste natus error sit
                        </Card.Text>
                        <div className='Video-details'>
                            <p ><a href="" className='views'><VisibilityIcon /> 97 Views</a></p>
                            <p><a href="" className='views'><ThumbUpOffAltIcon /> 20 likes</a></p>
                            <p><a href="" className='views'><CommentIcon /> 10 comments</a></p>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default Videoscard
