import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Categories from './Categories';
import {
  Link,
} from "react-router-dom";

const Sidebar = () => {
  return (
    <div className='Sidebar-lft d-lg-block d-none'>
      <h5>Top Channels</h5>
      <Link to="/channel"  className='flex-box-two'>
        <div>
          <img src="images/Music.jpg" alt="" />
        </div>
        <div className='channel-details'>
          <p className='channel-name'>Music Channel</p>
          <p className='videos-number'>8 Videos</p>
          <p className='views'><VisibilityIcon /> 27921 Views</p>
        </div>
      </Link>

      <Link to="/channel"  className='flex-box-two'>
        <div>
          <img src="images/Music.jpg" alt="" />
        </div>
        <div className='channel-details'>
          <p className='channel-name'>TV Channel</p>
          <p className='videos-number'>8 Videos</p>
          <p className='views'><VisibilityIcon /> 27921 Views</p>
        </div>
      </Link>

      <Link to="/channel"  className='flex-box-two'>
        <div>
          <img src="images/Music.jpg" alt="" />
        </div>
        <div className='channel-details'>
          <p className='channel-name'>Movie Channel</p>
          <p className='videos-number'>8 Videos</p>
          <p className='views'><VisibilityIcon /> 3978 Views</p>
        </div>
      </Link>

      <Link to="/channel"  className='flex-box-two'>
        <div>
          <img src="images/Music.jpg" alt="" />
        </div>
        <div className='channel-details'>
          <p className='channel-name'>Tech Channel</p>
          <p className='videos-number'>8 Videos</p>
          <p className='views'><VisibilityIcon /> 3278 Views</p>
        </div>
      </Link>


      <Categories />
    </div>
  )
}

export default Sidebar