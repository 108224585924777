import React from 'react';
import Sidebar from '../Components/Sidebar-lft/Sidebar';
import LabTabs from '../Components/Tabs/Tab';
import Channel from '../Components/Sidebar-lft/Channel';
import { Route, Routes } from 'react-router-dom';
import Playlist from '../Components/Tabs/Playlist';

const Allpages = () => {
  return (
    <div>
      <div className='App_flex_box'>
        <div className='Sidebar'>
          <Sidebar />
        </div>
        <div className='flex-grow-1'>
          <Routes>
            <Route path="/" element={<LabTabs />} />
            <Route path="/channel" element={<Channel />} />
            <Route path="/videos" element={<Playlist />} />
          </Routes>

        </div>
      </div>
    </div>
  )
}

export default Allpages
